import { ChartOptions, Interaction, ScaleType } from "chart.js"
import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import * as colors from "../colors"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { hoverPlugin } from "../plugins/hover"
import { LABEL_FONT_SIZE, wrapText } from "../utils"

const BarChart: React.FC<any> = ({ data }) => {
    const { form } = useChart()
    const { chart } = form.values
    const { yAxis, labelwidth } = chart
    const dctx = useDataContext()
    const indexAxis = chart.chartjs?.options?.indexAxis ?? "y"
    const valueAxis = indexAxis === "y" ? "x" : "y"
    const scaleType: ScaleType = yAxis.logscale ? "logarithmic" : "linear"
    const unit = utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space)

    const numericalScale = {
        type: scaleType,
        stacked: yAxis.grouped === "stack",
        min: yAxis.min ?? undefined,
        max: yAxis.max ?? undefined,
        suggestedMin: dctx.getAxisLimit("yAxis", "min"),
        suggestedMax: dctx.getAxisLimit("yAxis", "max"),
        ticks: {
            display: chart.yAxis.axislabels,
            callback: (v: any) => utils.dataLabelFormatter(v, unit, yAxis),
        },
        border: {
            display: false,
        },
        grid: {
            display: chart.yAxis.gridlines,
        },
    }

    const categoricalScale = {
        grid: { display: false },
        stacked: yAxis.grouped !== "discrete",
        ticks: {
            display: chart.categorylabels,
            callback: (i: any) => {
                return wrapText(data.labels[i], labelwidth)
            },
        },
    }

    // Chart options
    const options: ChartOptions<"bar"> = {
        ...chart.chartjs.options,
        indexAxis,
        scales: {
            [indexAxis]: categoricalScale,
            [valueAxis]: numericalScale,
        },
        animation: false,
        onHover: (e, active, chart) => {
            const nearestElement = Interaction.modes.point(chart, e, {
                axis: indexAxis,
                intersect: false,
            })
            hoverPlugin(e, nearestElement, chart)
        },
        plugins: {
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            legend: {
                display: false,
            },
            datalabels: {
                align: "center",
                anchor: "center",
                formatter: (v, ctx) =>
                    utils.dataLabelFormatter(v, yAxis.showInLabel ? unit : "", yAxis),
                clamp: true,
                clip: true,
                display: (ctx) => {
                    if (!chart.datalabels) return false

                    const value = ctx.dataset.data[ctx.dataIndex] as number

                    const valueStr = utils.dataLabelFormatter(
                        value,
                        yAxis.showInLabel ? unit : "",
                        yAxis,
                    )

                    const rectWidth =
                        ctx.chart.scales.x.getPixelForValue(value) - ctx.chart.scales.x.left
                    const labelWidth = ctx.chart.ctx.measureText(valueStr + "m").width

                    return labelWidth < rectWidth ? "auto" : false
                },
                // based on background, choose white or black text
                color: (ctx) => {
                    const backgroundColor = ctx.dataset.backgroundColor
                    return colors.isBright(backgroundColor as string) ? "#555" : "#fff"
                },
                font: {
                    size: LABEL_FONT_SIZE,
                },
            },
            tooltip: {
                mode: "point",
                callbacks: {
                    title: (ctx) => {
                        const label = ctx[0].dataset.label ?? ""
                        return dctx.getDisplayLabel("yAxis", label)
                    },
                    label: (ctx) => {
                        const label = data.labels[ctx.dataIndex]
                        return `${label}: ${utils.dataLabelFormatter(
                            ctx.parsed[valueAxis],
                            unit,
                            yAxis,
                        )}`
                    },
                },
            },
        },
    }

    return <Chart type="bar" options={options} data={data} plugins={[ChartDataLabels]} />
}

export default BarChart
