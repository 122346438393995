import { BubbleDataPoint, ChartOptions } from "chart.js"
import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { wrapText } from "../utils"

export interface DFIBubbleDataPoint extends BubbleDataPoint {
    i: number
}

const ScatterPlot: React.FC<any> = ({ data }) => {
    const { form, table } = useChart()
    const { chart } = form.values
    const { xAxis, yAxis, size, labelwidth } = chart
    const dctx = useDataContext()
    const xUnit = xAxis ? utils.buildUnit(xAxis.unit, xAxis.prefix, xAxis.space) : ""
    const yUnit = yAxis ? utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space) : ""
    const sizeUnit = size ? utils.buildUnit(size.unit, size.prefix, size.space) : ""

    if (!data.datasets.length) {
        return <span>Please select X and Y Indicators</span>
    }

    const xLabel = dctx.getDisplayLabel("xAxis", xAxis?.selected[0] ?? "")
    const yLabel = dctx.getDisplayLabel("yAxis", data.datasets[0].label)
    const sizeLabel = dctx.getDisplayLabel("size", size?.selected[0] ?? "")
    const sizeCol = size ? table.data[size?.selected[0]] : []
    const sizeMultiplier = size?.multiplier || 1

    // Chart options
    const options: ChartOptions<"bubble"> = {
        ...form.values.chart.chartjs.options,
        animation: false,
        scales: {
            x: {
                title: {
                    display: chart.xAxis?.axislabels ?? true,
                    text: xLabel,
                },
                type: xAxis?.logscale ? "logarithmic" : "linear",
                min: xAxis?.min ?? undefined,
                max: xAxis?.max ?? undefined,
                suggestedMin: dctx.getAxisLimit("xAxis", "min"),
                suggestedMax: dctx.getAxisLimit("xAxis", "max"),
                ticks: {
                    display: chart.xAxis?.axislabels,
                    callback: (v) =>
                        `${utils.displayCompact(
                            Number(v),
                            xUnit,
                            xAxis?.precision,
                            xAxis?.prefix,
                        )}`,
                },
                grid: {
                    display: chart.xAxis?.gridlines,
                },
            },
            y: {
                title: {
                    display: chart.yAxis.axislabels,
                    text: yLabel,
                },
                type: yAxis.logscale ? "logarithmic" : "linear",
                min: yAxis.min ?? undefined,
                max: yAxis.max ?? undefined,
                suggestedMin: dctx.getAxisLimit("yAxis", "min"),
                suggestedMax: dctx.getAxisLimit("yAxis", "max"),
                ticks: {
                    display: chart.yAxis.axislabels,
                    callback: (v) =>
                        `${utils.displayCompact(Number(v), yUnit, yAxis.precision, yAxis.prefix)}`,
                },
                grid: {
                    display: chart.yAxis.gridlines,
                },
            },
        },
        plugins: {
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            legend: {
                display: false,
            },
            tooltip: {
                usePointStyle: true,
                callbacks: {
                    title: (context) => {
                        const point = context[0].raw as DFIBubbleDataPoint
                        return dctx.getDisplayLabel("dimensions", dctx.dimensions.values[point.i])
                    },
                    label: function (context) {
                        const displayX = utils.displayCompact(
                            context.parsed.x,
                            xUnit,
                            xAxis?.precision,
                            xAxis?.prefix,
                        )
                        const displayY = utils.displayCompact(
                            context.parsed.y,
                            yUnit,
                            yAxis.precision,
                            yAxis.prefix,
                        )

                        const label = [`${xLabel}: ${displayX}`]
                        label.push(`${yLabel}: ${displayY}`)
                        if (sizeCol?.length) {
                            const point = context.raw as DFIBubbleDataPoint
                            const sizeValue = sizeCol[point.i] * sizeMultiplier
                            const displaySize = utils.displayCompact(
                                sizeValue,
                                sizeUnit,
                                size?.precision,
                                size?.prefix,
                            )
                            label.push(`${sizeLabel}: ${displaySize}`)
                        }
                        return label
                    },
                },
            },
            datalabels: {
                display: (context) => chart.datalabels && context.dataset.order === 1,
                formatter: (value, context) => {
                    const point = context.dataset.data[context.dataIndex] as DFIBubbleDataPoint
                    const label = dctx.getDisplayLabel(
                        "dimensions",
                        dctx.dimensions.values[point.i],
                    )
                    return wrapText(label, labelwidth)
                },
                font: {
                    size: 9,
                },
                align: -30,
                offset: (context) => {
                    const point = context.dataset.data[context.dataIndex] as DFIBubbleDataPoint
                    return point.r - 4
                },
                textStrokeColor: "#fff",
                textStrokeWidth: 2,
            },
        },
    }

    return <Chart type="bubble" options={options} data={data} plugins={[ChartDataLabels]} />
}

export default ScatterPlot
