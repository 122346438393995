import { ChartOptions } from "chart.js"
import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import * as colors from "../colors"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { hoverPlugin } from "../plugins/hover"
import { LABEL_FONT_SIZE, wrapText } from "../utils"

const Marimekko: React.FC<any> = ({ data }) => {
    const { form } = useChart()
    const { chart } = form.values
    const { xAxis, yAxis, labelwidth } = chart
    const dctx = useDataContext()
    const unit = utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space)

    // Chart options
    const options: ChartOptions<"bar"> = {
        ...chart.chartjs.options,
        indexAxis: "x",
        scales: {
            y: {
                type: xAxis?.logscale ? "logarithmic" : "linear",
                stacked: true,
                min: yAxis.min ?? undefined,
                max: yAxis.max ?? undefined,
                suggestedMin: dctx.getAxisLimit("yAxis", "min"),
                suggestedMax: dctx.getAxisLimit("yAxis", "max"),
                ticks: {
                    display: chart.yAxis.axislabels,
                    callback: (v) => utils.dataLabelFormatter(Number(v), unit, yAxis),
                },
                grid: {
                    display: chart.yAxis.gridlines,
                },
                border: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                grid: { display: false },
                ticks: {
                    display: chart.categorylabels,
                    callback: (i: any) => {
                        return wrapText(data.labels[i], labelwidth)
                    },
                },
            },
        },
        animation: false,
        onHover: hoverPlugin,
        plugins: {
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            legend: {
                display: false,
            },
            tooltip: {
                mode: "point",
                callbacks: {
                    title: (ctx) => {
                        const label = ctx[0].dataset.label ?? ""
                        return dctx.getDisplayLabel("yAxis", label)
                    },
                    label: (ctx) => {
                        const label = data.labels[ctx.dataIndex]
                        return `${label}: ${utils.dataLabelFormatter(ctx.parsed.y, unit, yAxis)}`
                    },
                },
            },
            datalabels: {
                align: "center",
                anchor: "center",
                formatter: (v, ctx) =>
                    utils.dataLabelFormatter(v, yAxis.showInLabel ? unit : "", yAxis),
                clamp: true,
                clip: true,
                display: (ctx) => {
                    if (!chart.datalabels) return false

                    const value = ctx.dataset.data[ctx.dataIndex] as number
                    const valueStr = utils.dataLabelFormatter(value, unit, yAxis)

                    const rectWidth =
                        ctx.chart.scales.x.getPixelForValue(value) - ctx.chart.scales.x.left
                    const labelWidth = ctx.chart.ctx.measureText(valueStr + "m").width

                    return labelWidth < rectWidth ? "auto" : false
                },
                // based on background, choose white or black text
                color: (ctx) => {
                    const backgroundColor = ctx.dataset.backgroundColor
                    return colors.isBright(backgroundColor as string) ? "#555" : "#fff"
                },
                font: {
                    size: LABEL_FONT_SIZE,
                },
            },
        },
    }

    return <Chart type="bar" options={options} data={data} plugins={[ChartDataLabels]} />
}

export default Marimekko
