import { Box } from "@mantine/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { DFIChartConfig, DFIChartDisplay } from "./dfi-charts-library"
import { ChartConfig } from "./dfi-charts-library/src/config"
import { dt } from "./dfi-utils"

const BASE_URL = process.env.REACT_APP_ASSET_BASE_URL

export const ChartDisplay = () => {
    const { chartid } = useParams()
    const [params, setSearchParams] = useSearchParams()
    const [DFIChartConfig, setDFIChartConfig] = useState<DFIChartConfig | null>(null)
    const [chartData, setChartData] = useState<dt.DFIDataTable | null>(null)
    const [chartNotFound, setNotFound] = useState(false)

    async function fetchFileData(filename: string) {
        const queryURL = `${BASE_URL}/${chartid}/${filename}`
        try {
            const response = await fetch(queryURL)
            const jsonData = await response.json()
            return jsonData
        } catch (error) {
            setNotFound(true)
            throw new Error(`config file for chart ID ${chartid} is invalid or not found`)
        }
    }

    useEffect(() => {
        fetchFileData("config.json")
            .then((configResponse) => {
                const { chart } = configResponse as { chart: ChartConfig }
                setDFIChartConfig({ code: chartid, chart })
            })
            .catch((err) => console.error(err))
        fetchFileData("data.json")
            .then((table) => {
                setChartData(table)
            })
            .catch((err) => console.error(err))
    }, [chartid])

    return (
        <Box key={chartid} className="chart-container">
            {chartNotFound ? (
                // TODO: make this a 404 page
                <h1>404: We couldn't find a chart with ID {chartid}</h1>
            ) : (
                DFIChartConfig &&
                chartData && (
                    <DFIChartDisplay
                        table={chartData}
                        config={DFIChartConfig}
                        adminControls={params.get("_admin") === "1"}
                    />
                )
            )}
        </Box>
    )
}
