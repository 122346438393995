import { Point } from "chart.js"
import { utils } from "../../dfi-utils"

const CHARTS_PATH = process.env.REACT_APP_CHARTS_BASE
const SHARE_PATH = process.env.REACT_APP_SHARE_BASE

export interface DFIPoint extends Point {
    i: number
}

export const colorPalette: { [color: string]: string[] } = {
    default: [
        "#003f5c", // blue
        "#3EA49D", // teal
        "#955196", // purple
        "#D04949", // pink-red
        "#FFA600", // yellow
    ],
    extended: [
        "#003F5C", // blue
        "#444E86", // purple
        "#955196", // purple
        "#DD5182", // pink
        "#FF6E54", // orange
        "#FFA600", // yellow
        "#CD3E3E", // red
        "#A56931", // brown
        "#3EA49D", // teal
        "#187850", // green
    ],
}

export const colorSchemeOptions = Object.keys(colorPalette).map((item: string) => ({
    label: utils.titlecase(item),
    value: item,
}))

export function calculateGrowth(data: Point[]) {
    const first = data[0].y
    data.forEach((value, index) => {
        value.y = ((value.y - first) / first) * 100
    })
}

export function calculateChange(data: Point[]) {
    let prevVal = data[0].y
    data.forEach((value, index) => {
        // percentage change from previous value
        const newVal = ((value.y - prevVal) / prevVal) * 100
        prevVal = value.y
        value.y = newVal
    })
}

export function calculateCumulative(data: Point[]) {
    data.forEach((value, index) => {
        if (index > 0) {
            value.y = value.y + data[index - 1].y
        }
    })
}

export function linearScale(istart: number, istop: number, ostart: number, ostop: number) {
    return function scale(value: number) {
        const fraction = (value - istart) / (istop - istart || 1)
        return ostart + (ostop - ostart) * fraction
    }
}

export function wrapText(text: string, limit: number) {
    if (!limit || text.length <= limit) return [text]

    const words: string[] = []
    return text.split(" ").reduce((result, currentWord) => {
        const index = result.length - 1
        const line = result[index]
        if (!line || line.length + currentWord.length + 1 > limit) {
            result.push(currentWord)
        } else {
            result[index] += " " + currentWord
        }
        return result
    }, words)
}

export const chartEmbedCode = (chart_id: string, height: number) => {
    return `
    <iframe loading="lazy"
        style="width: 100%; height: ${height ?? CHART_HEIGHT}px; border: 0px none;"
        allow="clipboard-write"
        src="${CHARTS_PATH}/${chart_id}">
    </iframe>`
}

export const shareChartUrl = (chart_id: string) => {
    return `${SHARE_PATH}/${chart_id}`
}

export const CHART_WIDTH = 700
export const CHART_HEIGHT = 500
export const DEFAULT_FOOTNOTE = "Created by Data For India"
export const defaultTimeCol = "year"
export const tileIndicatorsCode = "__indicator__"
export const LABEL_WIDTH = 15
export const LABEL_FONT_SIZE = 10
export const LABEL_PADDING = 5
export const GRID_HEIGHT = 320
export const MIN_CHART_HEIGHT = 75
export const CANVAS_WIDTH = 1440
export const CANVAS_HEIGHT = 810

export const mapOptions = {
    defaultColor: "#003f5c",
    borderColor: "#383838",
    missingColor: "#fff",
}

export const tagsList = [
    "Population",
    "Economy",
    "Health",
    "Work",
    "Living Conditions",
    "Education",
]
